.word-style {
  font-size: x-large !important;
}

.word-search-style {
  font-size: large !important;
}

.container-size {
  height: 40rem;
}
