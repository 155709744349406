.sidebar {
  height: 100%;
  width: 5rem;
  position: fixed;
  z-index: 1;
  top: 4.35rem;
  right: 0;
  transition: 0.8s;
  padding-top: 1rem;
  white-space: nowrap;
  background-color: #b96a49;
  color: #ffffff;
  .icon {
    fill: white;
  }
}

.sidebar-closed {
  width: 3.5rem;
}

.sidebar-open {
  width: 12rem;
}
.sidebar-open span {
  margin-left: 0.5rem;
  animation-name: opacity;
  animation-duration: 1.5s;
}

.sidebar-title {
  position: absolute;
  top: 1.3rem;
  padding-bottom: 0.9rem;
  padding-left: 4rem;
  animation-name: opacity;
  animation-duration: 1.5s;
  font-size: 1.4vw;
  width: 13rem;
}

.sidebar-profile-icon {
  position: absolute;
  top: 1.5rem;
  padding-bottom: 0.7rem;
  padding-left: 1.5rem;
  animation-name: opacity;
  animation-duration: 1.5s;
  width: auto;
}

.sidebar-open a,
.sidebar-open li {
  display: block;
  color: #ffffff;
  background-color: #b96b4900;
}
.sidebar-closed a,
.sidebar-open li {
  display: block;
  color: #ffffff;
}

.sidebar-open a:hover {
  opacity: 1;
  transition: opacity 1.3s ease-in-out;
  background-color: #ffffff;
  color: #b96a49;
  .icon {
    fill: #b96a49;
  }
}

.nav .nav-item .nav-link:focus {
  color: #ffffff !important;
}

.list-group-item {
  opacity: 1;
  transition: opacity 1.3s ease-in-out;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 490px) {
  .sidebar {
    height: 100%;
    width: 4rem;
    position: fixed;
    z-index: 1;
    top: 4.35rem;
    right: -1.2rem;
    transition: 0.8s;
    padding-top: 2rem;
    white-space: nowrap;
    background-color: #b96a49;
    color: #ffffff;
    .icon {
      fill: white;
    }
  }
  .sidebar-closed {
    width: 4rem;
  }

  .sidebar-open {
    width: 10.5rem;
  }
  .sidebar-open span {
    margin-left: 0.5rem;
    animation-name: opacity;
    animation-duration: 1.5s;
  }
  .sidebar-open a,
  .sidebar-open li {
    display: block;
    color: #ffffff;
    background-color: #b96b4900;
  }
  .sidebar-closed a,
  .sidebar-open li {
    display: block;
    color: #ffffff;
  }

  .sidebar-open a:hover {
    opacity: 1;
    transition: opacity 1.3s ease-in-out;
    background-color: #ffffff;
    color: #b96a49;
    .icon {
      fill: #b96a49;
    }
  }
}

.link-size {
}

@media only screen and (max-width: 765px) and (min-width: 490px) {
  .sidebar {
    height: 100%;
    width: 4rem;
    position: fixed;
    z-index: 1;
    top: 4.35rem;
    right: -0.4rem;
    transition: 0.8s;
    padding-top: 2rem;
    white-space: nowrap;
    background-color: #b96a49;
    color: #ffffff;
    .icon {
      fill: white;
    }
  }
  .sidebar-closed {
    width: 4rem;
  }

  .sidebar-open {
    width: 10.5rem;
  }
  .sidebar-open span {
    margin-left: 0.5rem;
    animation-name: opacity;
    animation-duration: 1.5s;
  }
  .sidebar-open a,
  .sidebar-open li {
    display: block;
    color: #ffffff;
    background-color: #b96b4900;
  }
  .sidebar-closed a,
  .sidebar-open li {
    display: block;
    color: #ffffff;
  }

  .sidebar-open a:hover {
    opacity: 1;
    transition: opacity 1.3s ease-in-out;
    background-color: #ffffff;
    color: #b96a49;
    .icon {
      fill: #b96a49;
    }
  }
}
