* {
  font-family: "Noto Naskh Arabic", serif;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(36, 36, 36);
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e0bf99;
}
