.header {
  position: sticky;
  top: 0;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.2rem 1rem rgb(206, 206, 206);
}
.logo {
  width: 3rem;
  margin-left: 1rem;
}
